import { get, has } from 'lodash'
export default class Payment {
  constructor (init = {}) {
    this.id = init?.id
    this.user_name = init?.user_name
    this.user_email = init?.user_email
    this.merchant_id = init?.merchant_id
    this.created_at = init?.created_at
    this.merchant_invoice_id = init?.merchant_invoice_id
    this.merchant_status = init?.merchant_status
    this.tariff_name = init?.tariff_name
    this.tariff_days = init?.tariff_days
    this.tariff_start = init?.tariff_start
    this.status_name = init?.status_name
    this.status = init?.status
    this.price = (init?.price_amount || '—') + ' ' + (init?.price_currency || '—')
    this.pay = (init?.pay_amount || '—') + ' ' + (init?.pay_currency || '—')
    this.actuallyPaid = has(init, 'actually_paid') && init.actually_paid !== null ? get(init, 'actually_paid') + ' ' + (init?.pay_currency || '—') : '-'
    this.created_at_str = (this.created_at || this.tariff_start).split(' ')[0]

    this._cellClasses = {
      user_name: 'text-muted text-nowrap',
      tariff_start: 'text-nowrap',
      price: 'text-nowrap',
      pay: 'text-nowrap',
      actuallyPaid: 'text-nowrap',
      status_name: 'text-nowrap',
      created_at_str: 'text-nowrap',
    }

    switch (this.status) {
      case 'new': this._cellClasses.status_name += ' ' + 'text-primary'; break
      case 'pending': this._cellClasses.status_name += ' ' + 'text-warning'; break
      case 'succeeded': this._cellClasses.status_name += ' ' + 'text-success'; break
      case 'canceled': this._cellClasses.status_name += ' ' + 'text-danger'; break
    }

    switch (this.merchant_status) {
      case 'new': this._cellClasses.merchant_status += ' ' + 'text-primary'; break
      case 'pending':
      case 'waiting': this._cellClasses.merchant_status += ' ' + 'text-warning'; break
      case 'succeeded':
      case 'finished': this._cellClasses.merchant_status += ' ' + 'text-success'; break
      case 'canceled': this._cellClasses.merchant_status += ' ' + 'text-danger'; break
    }
  }
}
