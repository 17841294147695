var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payments d-flex flex-column" }, [
    _c(
      "div",
      { staticClass: "title-wrap mb-3 d-flex" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("lk.header.payments"))),
        ]),
        _vm.$store.getters["user/getPermissions"]("view_payments_all")
          ? _c("v-select-input", {
              staticClass: "mb-0 flex-grow-1 v-select-input",
              attrs: {
                options: _vm.userList,
                "get-option-label": (option) => option.email,
                placeholder: _vm.$t("lk.payments.searchPlaceholder"),
              },
              on: { search: _vm.onSearch },
              scopedSlots: _vm._u(
                [
                  {
                    key: "prepend",
                    fn: function () {
                      return [
                        _c(
                          "CButton",
                          {
                            staticClass: "shadow-none",
                            attrs: { color: "primary" },
                          },
                          [
                            _c("CIcon", { attrs: { name: "cil-search" } }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("lk.payments.search")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "no-options",
                    fn: function () {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$t("general.emptySearch")) + " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3061076986
              ),
              model: {
                value: _vm.user,
                callback: function ($$v) {
                  _vm.user = $$v
                },
                expression: "user",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex-grow-1 overflow-hidden" },
      [
        _c("payments-table", {
          ref: "table",
          staticClass: "h-100 overflow-hidden",
          attrs: { user: _vm.user },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }