<template>
  <div class="payments d-flex flex-column">
    <div class="title-wrap mb-3 d-flex">
      <h1 class="title">{{$t('lk.header.payments')}}</h1>

      <v-select-input
        v-if="$store.getters['user/getPermissions']('view_payments_all')"
        v-model="user"
        class="mb-0 flex-grow-1 v-select-input"
        :options="userList"
        :get-option-label="option => option.email"
        :placeholder="$t('lk.payments.searchPlaceholder')"
        @search="onSearch"
      >
        <template #prepend>
          <CButton class="shadow-none" color="primary">
            <CIcon name="cil-search"/> {{$t('lk.payments.search')}}
          </CButton>
        </template>
        <template #no-options>
          {{$t('general.emptySearch')}}
        </template>
      </v-select-input>
    </div>

    <div class="flex-grow-1 overflow-hidden">
      <payments-table
        ref="table"
        :user="user"
        class="h-100 overflow-hidden"
      />
    </div>
  </div>
</template>

<script>
import PaymentsTable from "@/components/payments/PaymentsTable";
import VSelectInput from "@/components/VSelectInput";
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  name: "Payments",
  components: {
    PaymentsTable,
    VSelectInput
  },
  data() {
    return {
      user: null,
      userList: [],
      loadingRequest: false
    }
  },
  methods: {
    onSearch(search, loading) {
      if(search.length && search.length >= 3) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.get('api/v1/users/autocomplete', {params: {term: search}})
        .then(res => {
          vm.userList = res.data.data
        })
        .finally(() => {
          loading(false);
        })
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: none;

  @include media-breakpoint-up(sm) {
    margin-right: 3rem;
    display: block;
    font-size: 1.25rem;
    margin-bottom: .5rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.25rem;
  }
}

/deep/ .v-select-input {
  @include media-breakpoint-down(xs) {
    .input-group-prepend {
      display: none;
    }
  }
  @include media-breakpoint-up(sm) {
    //padding-right: 1.5rem;
  }

  .input-group-text {
    padding: 0;
    border: none;
  }
}

.title-wrap {
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }
}

.payments {
  @include media-breakpoint-down(lg) {
    @media (min-height: 500px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media (min-height: 650px) {
      height: 100%;
    }
  }
}
</style>
