<template>
  <CCard
    color="second-card-bg"
    class="shadow-none"
  >
    <CCardBody class="h-100 d-flex flex-column">
      <div class="table-wrap">
        <table class="table mb-0">
          <thead>
          <tr>
            <th v-for="(field, i) in fields" :key="i" :class="field._classes">
              {{ field.label }}
            </th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(item, i) in paymentList" :key="i">
            <td
              v-for="(field, i) in fields"
              :key="'field-'+i"
              :class="item._cellClasses[field.key]"
            >
              {{
                field.key !== 'status_name' ?
                  item[field.key] :
                  $t(`lk.payments.${item.status}`)
              }}
            </td>
            <CLoadingButtonCustom
              v-if="isNotProduction"
              color="primary"
              :loading="loadingTable"
              @click.native="finishPayment(item.id)"
            >
              Завершить платеж
            </CLoadingButtonCustom>
          </tr>
          </tbody>
        </table>
      </div>
      <CPagination
        class="mt-2 pagination-custom"
        :class="{_disabled: loadingTable}"
        v-show="totalPages > 1"
        :activePage.sync="page"
        :pages="totalPages"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import Payment from "@/assets/js/Payment.class";

export default {
  name: "PaymentsTable",
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  components: {},
  data() {
    return {
      loadingTable: false,
      paymentList: [],
      page: 1,
      totalPages: null,
      fields: [
        {key: 'id', _classes: 'align-middle', label: 'ID'},
        {key: 'user_name', _classes: 'align-middle', label: this.$t(('lk.payments.name'))},
        {key: 'user_email', _classes: 'align-middle', label: this.$t(('lk.payments.email'))},
        {key: 'created_at_str', _classes: 'align-middle', label: this.$t(('lk.payments.paymentDate'))},
        {key: 'merchant_id', _classes: 'align-middle', label: this.$t(('lk.payments.paymentID'))},
        {key: 'merchant_invoice_id', _classes: 'align-middle', label: this.$t(('lk.payments.invoiceID'))},
        {key: 'price', _classes: 'align-middle', label: this.$t(('lk.payments.price'))},
        {key: 'actuallyPaid', _classes: 'align-middle', label: this.$t(('lk.payments.actuallyPaid'))},
        {key: 'status_name', _classes: 'align-middle', label: this.$t(('lk.payments.status'))},
        {key: 'merchant_status', _classes: 'align-middle', label: this.$t(('lk.payments.merchantStatus'))},
        {key: 'tariff_name', _classes: 'align-middle', label: this.$t(('lk.payments.bundle'))},
        {key: 'tariff_days', _classes: 'align-middle', label: this.$t(('lk.payments.dueDate'))}
      ]
    }
  },
  created() {
    this.getPayments()
  },
  watch: {
    user: {
      deep: true,
      handler: function () {
        this.getPayments()
      }
    },
    page(val, oldVal) {
      if (val && val !== oldVal) {
        this.getPayments()
      }
    }
  },
  computed: {
    isNotProduction() {
      return process.env.NODE_ENV !== 'production' || !!process.env.VUE_APP_DEV
    }
  },
  methods: {
    getPayments() {
      const params = {
        page: this.page
      }

      if (this?.user?.id) params.user_id = this.user.id

      this.loadingTable = true
      axios.get('api/v1/payments', {params})
        .then(({data, status}) => {
          if (status < 300) {
            this.paymentList = data.data.map(v => new Payment(v))
            this.page = data.meta.current_page
            this.totalPages = data.meta.last_page
          }
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    finishPayment(payment_id) {
      this.loadingTable = true
      const fd = new FormData()
      fd.append('order_id', payment_id)
      fd.append('payment_status', 'finished')
      axios.post(`/api/v1/payments/hooks/nowPayments/local`, fd)
        .then((res) => {
          console.log(res)
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          this.loadingTable = false
          this.getPayments()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrap {
  overflow: auto;
  width: 100%;
  flex-grow: 1;
}

.table {
  position: relative;
  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}
</style>
