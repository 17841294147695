var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "shadow-none", attrs: { color: "second-card-bg" } },
    [
      _c(
        "CCardBody",
        { staticClass: "h-100 d-flex flex-column" },
        [
          _c("div", { staticClass: "table-wrap" }, [
            _c("table", { staticClass: "table mb-0" }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.fields, function (field, i) {
                    return _c("th", { key: i, class: field._classes }, [
                      _vm._v(" " + _vm._s(field.label) + " "),
                    ])
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.paymentList, function (item, i) {
                  return _c(
                    "tr",
                    { key: i },
                    [
                      _vm._l(_vm.fields, function (field, i) {
                        return _c(
                          "td",
                          {
                            key: "field-" + i,
                            class: item._cellClasses[field.key],
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  field.key !== "status_name"
                                    ? item[field.key]
                                    : _vm.$t(`lk.payments.${item.status}`)
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                      _vm.isNotProduction
                        ? _c(
                            "CLoadingButtonCustom",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.loadingTable,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.finishPayment(item.id)
                                },
                              },
                            },
                            [_vm._v(" Завершить платеж ")]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("CPagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalPages > 1,
                expression: "totalPages > 1",
              },
            ],
            staticClass: "mt-2 pagination-custom",
            class: { _disabled: _vm.loadingTable },
            attrs: { activePage: _vm.page, pages: _vm.totalPages },
            on: {
              "update:activePage": function ($event) {
                _vm.page = $event
              },
              "update:active-page": function ($event) {
                _vm.page = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }